<template>
  <div class="pickr-wrapper">
    <div class="pickr-wrap">
      <template v-if="dateProps.start">
        <!-- <input type="text" class="dateSelector start" placeholder="날짜를 선택하세요." /> -->
        <flat-pickr class="dateSelector" v-model="date.start" @on-close="setStartDate" :placeholder="$t('txt.selectDate')" :config="startConfig" />
      </template>
      <template v-if="dateProps.end">
        <span>~</span>
        <!-- <input type="text" class="dateSelector end" placeholder="날짜를 선택하세요." /> -->
        <flat-pickr class="dateSelector" v-model="endDate" @on-close="setEndDate" :placeholder="$t('txt.selectDate')" :config="endConfig" />
      </template>
    </div>
    <!-- <template v-if="dateProps.start && dateProps.end">
      <div class="date-range-selector-container">
      <div class="range-selector-wrap" v-for="(range, index) in dateRange" :key="range">
        <input type="radio" :id="`dateSelector${index}`" name="date-selector" :value="range.value" @click="inputBtnDate" :checked="range.checked" />
        <label :for="`dateSelector${index}`">{{ range.text }}</label>
      </div>
    </div>
    </template> -->
  </div>
</template>
<script>
// import flatpickr from "flatpickr";
import flatPickr from "vue-flatpickr-component";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/flatpickr.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";

export default {
  name: "DatePicker",
  components: {
    flatPickr
  },
  props: {
    dateProps: {
      type: Object,
      default: {
        start: true,
        end: true
      }
    },
    startDefault: {
      type: Date,
      defalut: new Date()
    },
    endDefault: {
      type: Date,
      defalut: new Date()
    }
  },
  data() {
    return {
      datepickr: "",
      date: {},
      startConfig: {
        locale: Korean,
        defaultDate: this.startDefault,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "Y-m",
            ariaDateFormat: "Y-m",
          })
        ]
      },
      endConfig: {
        locale: Korean,
        defaultDate: this.endDefault,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m"
          })
        ]
      },
      selectedRange: "",
      dateRange: [
        {
          name: "today",
          text: "오늘",
          value: 0
        },
        {
          name: "aweek",
          text: "1주",
          value: 6
        },
        {
          name: "weeks",
          text: "2주",
          value: 13
        },
        {
          name: "amonth",
          text: "한달",
          value: 30
        }
      ]
    };
  },
  created() {
    console.log('111', this.startDefault)
    // console.log(this.endDefault)
    this.setDefalutDate();
  },
  // mounted() {
  //   this.createDatePicker();
  // },
  methods: {
    maxDate() {
      const today = new Date();
      today.setHours(24, 0, 0);
      return today;
    },
    setStartDate(date) {
      // console.log(date);
      this.$emit("setStartDate", date);
    },
    setEndDate(date) {
      // console.log('kk')
      this.$emit("setEndDate", date);
    },
    // createDatePicker() {
    //   const setStartDate = this.setStartDate;
    //   const setEndDate = this.setEndDate;
    //   const maxDate = () => {
    //     const today = new Date();
    //     today.setHours(24, 0, 0);
    //     return today;
    //   };
    //   const config = {
    //     dateFormat: "Y-m-d",
    //     // time_24hr: true,
    //     enableTime: false,
    //     locale: korean,
    //     disableMobile: "true",
    //     maxDate: maxDate(),
    //     onChange(selectedDate, dateStr, instance) {
    //       const target = instance.element;
    //       if (target.classList.contains("start")) {
    //         setStartDate(selectedDate);
    //       } else {
    //         setEndDate(selectedDate);
    //       }
    //     }
    //   };
    //   this.datepickr = flatpickr(".dateSelector", config);
    //   if (Array.isArray(this.datepickr)) {
    //     for (const pickr of this.datepickr) {
    //       const classList = pickr.config.altInputClass;
    //       if (classList.indexOf("start") !== -1) {
    //         pickr.setDate(this.date.start);
    //       } else {
    //         pickr.setDate(this.date.end);
    //       }
    //     }
    //   }
    // },
    setDefalutDate() {
      if (!this.propDate) {
        this.date.start = new Date();
        this.date.start.setHours(0, 0, 0);
        this.date.end = new Date();
      } else {
        // this.date.start = new Date()
        // this.date.start.setDate(this.date.start-2)
        // this.date.start.setHours(0, 0, 0)
        // this.date.end = new Date()
      }
    },
    inputBtnDate(e) {
      const target = e.target;
      const selectedDays = target.value;
      const start = new Date();
      const end = new Date();
      if (this.selectedRange === target) {
        target.checked = false;
        start.setHours(0, 0, 0);
        this.selectedRange = "";
      } else {
        start.setDate(start.getDate() - selectedDays);
        start.setHours(0, 0, 0);
        this.selectedRange = target;
      }
      for (const pickr of this.datepickr) {
        let setDate;
        const pickrClass = pickr.config.altInputClass;
        if (pickrClass.indexOf("start") !== -1) {
          setDate = start;
        } else {
          setDate = end;
        }
        pickr.setDate(setDate);
      }
    }
  }
};
</script>

<style scoped>
.pickr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickr-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickr-wrap > span {
  font-weight: 800;
  font-size: 19px;
  margin: 0 5px;
}

.date-range-selector-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.range-selector-wrap {
  display: flex;
}
.range-selector-wrap > input {
  display: none;
}
.range-selector-wrap > input + label {
   box-sizing: border-box;
   border-radius: 2px;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
   border: solid 1px #808080;
   background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);
   padding: 4px 8px;
   color: #252930;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   cursor: pointer;
   margin-right: 5px;
   font-size: 12px;
}
.range-selector-wrap > input:checked + label {
  background: #f6f3e9;
}
.range-selector-wrap:last-child > input + label {
  margin-right: 0;
}
</style>
