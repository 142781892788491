<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="searchbar-content mr-5">
           <!-- <div class="title">
             <i class="fas fa-cog"></i>
             <span>{{ $t(`searchArea.schDiv`) }}</span> :
           </div> -->
           <select class="mr-5">
             <option>제목</option>
             <option>내용</option>
             <option selected>아이디</option>
           </select>
           <input type="text" placeholder="ID" />
         </div>
         <button class="btn-search" type="button" @click="pageSeach">
           <i class="fas fa-search"></i>
         </button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <!-- <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t(`searchArea.schResult`) }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">0</span>
          </div>
        </div>
      </article> -->
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <!-- <template v-if="tableData.length !== 0">
              <tr v-for="(item, idx) in tableData" :key="item.memId">
                <td>{{ idx + 1 }}</td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                </td>
                <td>{{ numberWithCommas(item.cashAmt) }}</td>
                <td>{{ numberWithCommas(item.pointAmt) }}</td>
                <td>{{ numberWithCommas(item.summaryAmtBalance) }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="modalOpen(item)">{{ $t(`button.pay`) }}</button>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="6">{{ $t('txt.noData') }}</td>
              </tr>
            </template> -->
            <tr>
              <td>1</td>
              <td class="noti_title">◈점검◈ 릴플레이/판타즈마/부메랑/포더플레이어/릴렉스 게이밍</td>
              <td>2023-11-29 17:15:35</td>
            </tr>
            <tr>
              <td>1</td>
              <td class="noti_title">◈점검◈ 릴플레이/판타즈마/부메랑/포더플레이어/릴렉스 게이밍</td>
              <td>2023-11-29 17:15:35</td>
            </tr>
            <tr>
              <td>1</td>
              <td class="noti_title">◈점검◈ 릴플레이/판타즈마/부메랑/포더플레이어/릴렉스 게이밍</td>
              <td>2023-11-29 17:15:35</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="modalActive">
      <div class="modal-wrap">
        <article class="page-contents-wrap" style="margin-bottom: 30px">
          <div class="sub-title">
            <h3>{{ $t('table.head.settlement') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.id') }}</div>
                <div class="item-content">
                  <input type="text" :value="payoutData.memId" readonly />
                </div>
              </div>
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.summaryBalance') }}</div>
                <div class="item-content">
                  <input type="text" :value="payoutData.comSummaryAmtBalance" readonly />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.body.money') }}</div>
                <div class="item-content">
                  <input type="text" :value="payoutData.comCash" readonly />
                </div>
              </div>
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.body.pointAmt') }}</div>
                <div class="item-content">
                  <input type="text" :value="payoutData.comPoint" readonly />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="page-contents-wrap">
          <div class="sub-title" style="height: 0; margin-bottom: 0"></div>
          <div class="searchwrap">
             <div class="searchBar">
               <div class="searchbar-content mr-5">
                 <div class="title">
                   <i class="fas fa-cog"></i>
                   <span>{{ $t('searchArea.dateSearch') }}</span> :
                 </div>
                 <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" :startDefault="startDefault" :dateProps="dateProps" />
               </div>
             </div>
          </div>
          <div class="page-content">
            <div class="item-row-container modal-table-container">
              <div class="table-wrapper">
                <table class="mainTable">
                  <table-head :headInfo="modalTheadInfo" />
                  <tbody>
                    <tr v-if="this.payoutData.month.length !== 0">
                      <td style="width: 8%">
                        <ui-check :id="`checkedItem${month}`" @setYn="checkedSum(this.payoutData.month[0])" />
                      </td>
                      <td>
                        <span class="fc-red">{{ this.dateInfo.currentMonth }}{{ $t('searchArea.month') }}</span>
                        <span>({{ this.dateInfo.currentMonthTerm }})</span>
                      </td>
                      <td>
                        {{ this.payoutData.month[0].comSummaryAmtBalance }}
                      </td>
                      <td style="width: 10%">
                        <div class="status-change-btn-wrap">
                          <button class="btn-innerTable btn-status-change refuse" type="button" @click="payout(this.payoutData.month[0])">{{ $t('button.pay') }}</button>
                        </div>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="4">{{ $t('txt.noData') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="item-row-container modal-table-container">
              <div class="table-wrapper" style="max-height: 166px; overflow-y: auto">
                <table class="mainTable">
                  <table-head :headInfo="modalTheadInfo" />
                  <tbody>
                    <template v-if="this.payoutData.week.length !== 0">
                      <tr v-for="(item, idx) in this.payoutData.week" :key="item.dateIdx">
                        <td style="width: 8%">
                          <ui-check :id="`checkedItem${idx}`" @setYn="checkedSum(item)" />
                        </td>
                        <td>
                          <span class="fc-red">{{ item.weeks }}{{ $t('searchArea.weeks') }}</span>
                          <span>({{ item.startDate }} - {{ item.endDate }})</span>
                        </td>
                        <td>{{ item.comSummaryAmtBalance }}</td>
                        <td style="width: 10%">
                          <div class="status-change-btn-wrap">
                            <button class="btn-innerTable btn-status-change refuse" type="button" @click="payout(item)">{{ $t('button.pay') }}</button>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="4">{{ $t('txt.noData') }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="table-wrapper">
                <table class="mainTable">
                  <tbody>
                    <tr>
                      <th>{{ $t('table.head.selectMoney') }}</th>
                      <td>
                        <input type="text" v-model="payoutData.comTotalPayoutAmt" disabled />
                      </td>
                      <td style="width: 10%">
                        <div class="status-change-btn-wrap">
                          <button class="btn-innerTable btn-status-change refuse" type="button" @click="payoutChoose">{{ $t('button.pay') }}</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="status-change-btn-wrap pt30" style="margin-bottom: 30px">
              <button class="btn-innerTable btn-status-change approve" type="button" @click="modalClose">{{ $t('button.close') }}</button>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from "lodash";
import UiCheck from "@/components/ui/UiCheck";
import TableHead from "@/components/main/table/Head.vue";
import Pagination from "@/components/common/Pagination";
import DateSelector from "@/components/common/DateSelector_monthly";
import { partnerBalanceList, partnerBalanceDetail, partnerSetPoint } from "@/api/partner.js";
import { getCode } from "@/api/common.js";
import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
import { getSiteData } from "@/libs/auth-helper";

export default {
  name: "PartnerPayout",
  components: {
    TableHead,
    Pagination,
    UiCheck,
    DateSelector
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ["index", "title01", "date"]
      },
      modalTheadInfo: {
        fstColumn: false,
        dataList: ["empty", "summaryTerm", "summaryAmt", "option"]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        memId: "",
        startDate: ""
      },
      searchDate: "",
      payoutData: {
        memId: "",
        cashAmt: 0,
        pointAmt: 0,
        summaryAmtBalance: 0,
        month: [],
        week: [],
        totalPayoutAmt: 0,
        comTotalPayoutAmt: "0"
      },
      dateInfo: {
        currentMonth: "",
        currentWeek: "",
        currentMonthTerm: "",
        currentWeekTerm: ""
      },
      tableData: [],
      pageInfo: {
        page:0
      },
      modalActive: false,
      dateProps: {
        start: true,
        end: false
      },
      startDefault: "",
      paidReqData: []
    };
  },
  watch: {
    startDefault: {
      handler(e) {
        console.log(e);
        if (this.modalActive) {
          this.setPayoutTable(this.payoutData);
        }
      }
    },
    paidReqData: {
      handler(e) {
        this.payoutData.totalPayoutAmt = 0;
        if (e.length !== 0) {
          for (const item of e) {
            this.payoutData.totalPayoutAmt += Number(item.summaryAmtBalance);
          }
          this.payoutData.comTotalPayoutAmt = numberWithCommas(this.payoutData.totalPayoutAmt);
          console.log(this.payoutData.comTotalPayoutAmt);
        } else {
          this.payoutData.totalPayoutAmt = 0;
          this.payoutData.comTotalPayoutAmt = "0";
        }
      },
      deep: true
    }
  },
  methods: {
    async setPoint(item) {
      const req = {
        memId: item.memId,
        dateIdx: item.dateIdx,
        PartnerPeriod: item.PartnerPeriod
      };
      const res = await partnerSetPoint(req);
      return res;
    },
    async payout(item) {
      const result = await this.setPoint(item);
      if(result.resultCode==="0"){
        this.setTableData(this.pageInfo.page);
        const req = {
          memId:item.memId,
          startDate: getDateStr(new Date(),"yyyy-MM")
        }
        const memDetail = await partnerBalanceList(req);
        this.modalDataReset();
        await this.setPayoutTable(memDetail.data.list[0]);
        alert('정산금 지급 완료');
      }else{
        alert('정산금 지급 실패, 다시 시도해주세요.');
      }
    },
    async payoutChoose(){
      const chooses = this.paidReqData;
      if(chooses.length!==0){
        let count = 0;
        for (const item of chooses){
          const result = await this.setPoint(item);
          if(result.resultCode==="0"){
            count++
          }else{
            alert('정산금 지급 실패, 다시 시도해주세요.');
            return;
          }
        }
        alert('정산금 지급 완료');
      }
    },
    setStartDate(date) {
      // console.log(date)
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setDate(1);
      } else {
        _date = date[0];
      }
      this.startDefault = _date;
      this.searchDate = getDateStr(_date, "yyyy-MM");
      this.reqData.startDate = getDateStr(_date, "yyyy-MM");
    },
    checkedSum(item) {
      const itemIdx = this.paidReqData.indexOf(item);
      if (itemIdx === -1) {
        this.paidReqData.push(item);
      } else {
        this.paidReqData.splice(itemIdx, 1);
      }
    },
    payoutSave() {
      this.modalClose();
    },
    getWeek(date) {
      console.log(date.getDate());
      return Math.ceil(date.getDate() / 7);
    },
    formatDate(date) {
      let year = date.getFullYear();
      let mymonth = date.getMonth() + 1;
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      let myweekday = date.getDate();
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      const dateTxt = `${year}-${mymonth}-${myweekday}`;
      return dateTxt;
    },
    setDateInfo() {
      let now = new Date();
      let nowDayOfWeek = now.getDay();
      let nowWeek = this.getWeek(now);
      let nowDay = now.getDate();
      let nowMonth = now.getMonth();
      let nowYear = now.getFullYear();
      let monthStartDate = new Date(nowYear, nowMonth, 1);
      let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
      let weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      let weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
      this.dateInfo.currentMonth = nowMonth + 1;
      this.dateInfo.currentMonthTerm = this.formatDate(monthStartDate) + " - " + this.formatDate(monthEndDate);
      this.dateInfo.currentWeek = nowWeek;
      this.dateInfo.currentWeekTerm = this.formatDate(weekStartDate) + " - " + this.formatDate(weekEndDate);
    },
    getWeekTerm() {
      this.dateInfo.currentWeek = nowWeek;
      this.dateInfo.currentWeekTerm = this.formatDate(weekStartDate) + " - " + this.formatDate(weekEndDate);
    },
    weekCount(today) {
      var year = today.getFullYear();
      var countDay = new Date(year, 0, 1);
      var week = 1;
      while (today > countDay) {
        countDay.setDate(countDay.getDate() + 1);
        var countNum = countDay.getDay();
        if (countNum == 0) {
          week++;
        } //if
      } //while
      return week;
    },
    sortNum(arr, key) {
      const reArr = arr.sort((a, b) => {
        return Number(a[key]) - Number(b[key]);
      });
      return reArr;
    },
    async setPayoutTable(item, type) {
      const req = {
        memId: item.memId,
        startDate: this.reqData.startDate
      };
      item.week = [];
      item.month = [];
      this.setDateInfo();
      const res = await partnerBalanceDetail(req);
      // console.log(res);
      const data = res.data.list;
      this.payoutData.memId = item.memId;
      this.payoutData.cashAmt = item.cashAmt;
      this.payoutData.comCash = numberWithCommas(item.cashAmt);
      this.payoutData.pointAmt = item.pointAmt;
      this.payoutData.comPoint = numberWithCommas(item.pointAmt);
      this.payoutData.summaryAmtBalance = item.summaryAmtBalance;
      this.payoutData.comSummaryAmtBalance = numberWithCommas(item.summaryAmtBalance);
      if (data) {
        const searchDateSplit = this.reqData.startDate.split("-");
        for (const item of data) {
          item.comSummaryAmtBalance = numberWithCommas(item.summaryAmtBalance);
          if (item.PartnerPeriod === "M") {
            this.payoutData.month.push(item);
          } else if (item.PartnerPeriod === "W") {
            const week = item.dateIdx.substr(4, 2);
            // console.log(week);
            item.weeks = Number(week);
            this.payoutData.week.push(item);
          }
        }
        if (this.payoutData.week.length !== 0) {
          // console.log('sort')
          this.payoutData.week = this.sortNum(this.payoutData.week, "weeks");
        }
      }
      // console.log(this.payoutData);
    },
    async modalOpen(item, type) {
      await this.setPayoutTable(item);
      if (type === "all") {
        // this.payoutData.
      }
      this.modalActive = !this.modalActive;
    },
    modalDataReset(){
      this.payoutData = {
        memId: "",
        cashAmt: 0,
        pointAmt: 0,
        summaryAmtBalance: 0,
        month: [],
        week: [],
        totalPayoutAmt: 0,
        comTotalPayoutAmt: "0"
      };
    },
    modalClose() {
      this.modalDataReset();
      this.setStartDate();
      this.searchMemId = "";
      this.modalActive = false;
    },
    async setTableData(page) {
      if (page) {
        this.reqData.page = page;
      } else {
        this.reqData.page = 1;
      }
      const req = lodash.cloneDeep(this.reqData);
      const res = await partnerBalanceList(req);
      console.log(res);
      if (res.data.pageInfo) {
        this.pageInfo = res.data.pageInfo;
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count);
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1);
      }
      this.tableData = res.data.list;
    }
  },
  async created() {
    this.setStartDate();
    this.setTableData(1);
  }
};
</script>

<style scoped>
.sub-title {
  font-size: 1em;
  font-weight: 700;
  color: var(--main);
  height: 32px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
  display: flex;
  height: 100%;
  align-items: center;
  width: 180px;
  font-size: 0.9em;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}
.sub-title > h3::before {
  content: "●";
  color: #18698b;
  margin-right: 7px;
}
.pt30 {
  padding-top: 30px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
.modal-wrap {
  width: 600px;
}
.item-title {
  min-width: 100px;
}
input[type="number"] {
  font-weight: 500;
  border: 1px solid #18698b;
  border-radius: 3px;
  height: 26px;
  padding: 0 6px;
  box-sizing: border-box;
}
.page-contents-wrap {
  margin-bottom: 0;
}
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-table-container {
  width: 100%;
}
.modal-table-container .table-wrapper {
  width: 100%;
  margin-bottom: 10px;
}
.modal-table-container .table-wrapper:last-child {
  margin-bottom: 0;
}
.modal-table-container .mainTable {
  font-size: 1em !important;
}
.noti_title {color: #4458b8; cursor: pointer; text-align: left;}
</style>
